$min-bar-height: 4.8rem;
$divider: #ececec;
/* very light grey */
$main-color: white;
$font-color: black;
$font-color-white: $main-color;
$font-opposed-color: black;
$main-opposed-color: white;
$menu-color: black;
$card-background-color: white;
$media-background-color: white;
$mainbar-background-color: #539A61;
$form-control-background-color: #ececec;
$menu-font-color: black;
$menu-selected: rgba(152, 233, 255, 0.812);
$menu: white;

@import "~bootstrap/scss/bootstrap";

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

.img-logo-spd {
  content: url("../assets/logo.png");
}

html {
  font-size: 14px;
}

.my-card {

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $main-color;
  }
}

// .nav-tabs .nav-link.active,
// .nav-tabs .nav-item.show .nav-link,
// .form-control,
// .form-control:read-only {
//   background-color: $form-control-background-color;
//   border-color: $main-color  !important;
//   border: 1px solid $main-color;
// }

*:focus:not(.focus-visible) {
  outline: none;
}

.my-card:focus-visible,
.my-small-card:focus-visible,
.btn:focus-visible,
.function-icon:focus-visible,
.nav-link[role="tab"]:focus-visible,
.my-profile-avatar:focus-visible {
  outline: red solid 4px !important;
}


body {
  font-family: 'Montserrat', sans-serif;
}

.block-with-text {
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  &.row-3 {
    -webkit-line-clamp: 3;
  }

  &.row-5 {
    -webkit-line-clamp: 5;
  }

  &.row-10 {
    -webkit-line-clamp: 10;
  }
}

.badge.bg-secondary {
  color: $font-opposed-color;
  background-color: $form-control-background-color !important;
}

.card.my-small-card {
  color: $font-opposed-color;
  border-radius: .6rem;
  //min-height: 23rem;

  background-color: rgba($card-background-color, .15);
  border: 1px solid rgba($card-background-color, .25);
  box-shadow: none;
  backdrop-filter: blur(15px);
}

.card.my-small-card .short-description {
  //padding: 0rem;
  //background-color: transparent;
}

.card.my-small-card .card-body .my-title {
  font-size: 1.3rem;
  font-weight: 500;
  overflow-wrap: break-word;
  min-height: 2rem;
  cursor: pointer;
}

.card.my-small-card:hover .card-body .my-title {
  color: $main-opposed-color;
}

.card.my-small-card:hover {
  background-color: rgba($card-background-color, 0.3);
  position: relative;
  //top:-1rem;
}

.card.my-card .card-body .area-image {
  min-height: 1.8rem;
  font-size: 1rem;
}

.card.my-card .card-body .area-image svg {
  margin-right: 0.8rem;
  height: 10rem;
}

.card.my-card .card-body .my-title {
  font-size: 1.4rem;
  font-weight: 500;
  color: $font-color;
  margin-bottom: 6px;
  overflow-wrap: break-word;
  height: 5rem;
}

.card.my-card:hover .card-body .my-title {
  color: $main-opposed-color;
}


.card.my-card .card-body .my-description {
  font-size: 1rem;
  color: $menu-color;
  font-style: italic;
  min-height: 4.4rem;
}

.card.my-card .card-footer {
  background-color: black;
  border-radius: .6rem !important;
}

.mainbar {
  min-height: $min-bar-height;
  background-color: $mainbar-background-color;

  @include media-breakpoint-up(md) {
    position: sticky;
    top: 0rem;
    z-index: 1040;
  }
}

.mainbarlink {
  margin-left: $spacer;
}

.mainbarlink:global(.active) {
  font-weight: 700;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.dropdown-menu {
  background-color: white;
  color: black;
}

.btn-my-outline-btn,
.active.btn-my-outline-btn:hover,
.btn-my-outline-opposed-btn:hover,
.active.btn-my-outline-opposed-btn {
  color: $main-opposed-color;
  border-color: $main-opposed-color;
  background-color: transparent;
}

.btn-my-outline-opposed-btn:disabled {
  color: $font-opposed-color;
  background-color: $main-opposed-color;
  border-color: $main-opposed-color;
}

.btn-my-outline-btn:hover,
.active.btn-my-outline-btn,
.btn-my-outline-opposed-btn,
.active.btn-my-outline-opposed-btn:hover {
  color: $font-opposed-color;
  background-color: $main-opposed-color;
  border-color: $main-opposed-color;
}

.main-banner {
  height: 25rem;
  margin-top: 5rem;
  margin-left: 5rem;
  margin-right: 5rem;
  padding: 20rem;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #297037;
  flex-direction: column;
  ;
}


.main-banner-text {
  font-size: 1.2rem;
  font-weight: 200;
  font-style: italic;
  position: relative;
  color: $font-color-white;
  min-width: 60%;
}

.lead p {
  font-weight: 400;
  font-style: italic;
}

.navbar-brand {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 36px;
  padding-right: 18px;

  img {
    max-height: 36px;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 18px;

    img {
      max-height: 28px;
    }
  }

  @media (max-width: 376px) {
    padding-left: 10px;

    img {
      max-height: 24px;
    }
  }

  @media (max-width: 350px) {
    img {
      padding-top: 4px;
      padding-right: 200px;
      max-height: 22px;
    }
  }
}

.menu-profile {
  position: absolute;
  padding-right: 8px;
  right: 62px;
  z-index: 999999;
  padding-right: 1.1rem;

  & button {
    margin-top: 10px;
    margin-right: 6px;
  }

  .dropdown {
    margin-right: 4px;
  }

  @include media-breakpoint-down(md) {
    position: absolute;
  }

  @include media-breakpoint-down(sm) {
    //padding-right: 120px;
    margin-top: 5px;
    right: 70px;

    //width: 100px;
    & button {
      margin-top: 3px;
      margin-left: 20px;
      font-size: 0.9rem;
      padding-left: 3px;
      padding-right: 3px;
      width: 88px;
    }

    .dropdown {
      margin-top: 3px;
    }
  }
}

.menu-accessibility {
  position: absolute;
  margin-top: 8px;
  top: 0;
  right: 0px;
  z-index: 999999;

  @include media-breakpoint-down(md) {
    position: absolute;
  }
}

svg.accessibility-icon,
.dropdown-toggle:active svg.accessibility-icon,
.dropdown-toggle:focus svg.accessibility-icon {
  width: 40px;
  fill: $menu-color;
}

.dropdown-toggle:hover svg.accessibility-icon {
  fill: $main-color;
}

.img-avatar {
  border-radius: 50% !important;
  width: 40px;
  height: 40px;
  object-fit: cover;

  &.small-avatar {
    width: 30px;
    height: 30px;
  }
}

.navbar-nav .show>.nav-link {
  color: $menu-selected;
}

.navbar-nav .nav-link.active {
  color: $menu-selected;
}

.nav-tabs .nav-link {
  color: rgb(53, 53, 53);
}

.nav-tabs .nav-link.active {
  color: black;
}

.nav-link:focus {
  color: $menu-selected;
}


.nav-link:active {
  color: $menu-selected;
}

.dropdown-item:active {
  background-color: $success;
}

.dropdown-item-text {
  color: inherit;
}

.profile-name.dropdown-item-text {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
}

.profile-email.dropdown-item-text {
  text-align: center;
  font-size: 0.8rem;
  font-weight: 300;
}


.dropdown-menu>.dropdown-item.active {
  background-color: $white;
  color: black;
}

.dropdown-menu>.dropdown-item:hover,
.dropdown-menu>.dropdown-item:focus {
  background-color: $divider;
  color: black;
}

.dropdown-menu>.dropdown-item {
  text-decoration: none;
  color: $menu-color;
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  border-radius: 0.6rem;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.my-media-video-default {
  position: relative;
  min-height: 92px;
  aspect-ratio: 16/9;
  margin: auto;
  background-color: $media-background-color;
  border-radius: 0.6rem;

  svg {
    max-height: 90%;
    max-width: 90%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 0.6rem;
    fill: $menu-color;
  }
}


.my-media-img {
  position: relative;
  height: 300px;
  margin: auto;
  background-color: $media-background-color;
  border-radius: 0.6rem;

  .my-media-default svg,
  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 0.6rem;
    fill: $menu-color;
  }
}

.my-any-media-img {
  margin: 0;
  padding: 0;

  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
  }
}

.overflowx-clip {
  overflow-x: clip;
}

.article-container {
  max-width: 1024px;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;

  img {
    max-height: 57px;
  }
}

.btn-login {
  color: white !important;
  background-color: #23272B;
  border-color: #23272B;
}

.btn-login:hover,
.btn-login:focus {
  color: black !important;
  background-color: $mainbar-background-color;
  border-color: $mainbar-background-color;
}

.btn-register {
  color: white !important;
  background-color: #23272B;
  border-color: #23272B;
}

.btn-register:hover,
.btn-register:focus {
  color: black !important;
  background-color: #ff6b6b;
  border-color: #ff6b6b;
}

.input-form-error {
  color: red !important;
  font-weight: 500;
}


.my-profile-avatar {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border: yellow 3px solid;
  border-radius: 50% !important;
}

.my-profile-view,
.my-form-view {
  width: 80%;
  max-width: 1024px;
}


.anime-title {
  padding-left: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-style: normal;
  text-decoration: none;
  color: $main-opposed-color;
  border-top: 2px solid $main-opposed-color;
  border-bottom: 2px solid $main-opposed-color;
  letter-spacing: 0.8rem;
  transition: .5s;
}

.anime-title:hover {
  letter-spacing: 4rem;
  font-weight: 100;
  border-top: 1px solid $main-opposed-color;
  border-bottom: 1px solid $main-opposed-color;
}

.anime-form-title {
  font-size: 1.1rem;
  letter-spacing: 0.6rem;
}

.anime-name {
  letter-spacing: 0.8rem;
}

.anime-form-title,
.anime-name {
  font-weight: 200;
  padding-left: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-style: normal;
  text-decoration: none;
  color: $main-opposed-color;
  border-top: 1px solid $main-opposed-color;
  border-bottom: 1px solid $main-opposed-color;
  transition: .5s;

  @include media-breakpoint-down(md) {
    color: black;
  }
}

.anime-form-title:hover,
.anime-name:hover {
  letter-spacing: 1rem;
  font-weight: 100;
}


.form-name {
  padding-top: 3.0rem;
  padding-bottom: 0rem;
}

.badge {
  font-weight: 500;
  padding-top: 0.4rem;
}

.badge.bg-primary {
  --bs-bg-opacity: 1;
  margin-left: 10px;
  background-color: $main-opposed-color !important;
  color: $font-opposed-color;
}

.my-profile-view .nav-tabs .nav-link:hover .badge.bg-primary,
button.active .badge.bg-primary {
  background-color: $font-opposed-color !important;
  color: $main-opposed-color;
}

.my-profile-view .nav-tabs,
.my-profile-view .nav-tabs .nav-link {
  margin-left: 4px;
  background-color: transparent;
  border-color: $main-opposed-color !important;
  color: $main-opposed-color !important;
}

.my-profile-view .nav-tabs .nav-link {
  margin-left: 4px;
  background-color: rgba($card-background-color, 0.9);
  border-color: $main-opposed-color !important;
  color: $main-opposed-color !important;
}

.my-profile-view .nav-tabs .nav-link:hover,
.my-profile-view .nav-tabs .nav-link.active {
  background-color: $main-opposed-color !important;
  border-color: $main-opposed-color !important;
  color: $font-opposed-color !important;
}

/*
.nav-link, .nav-link:hover {
  color: yellow;
}

.nav-tabs {
  border-bottom: 1px solid yellow;
}
*/

.function-icon {
  width: 26px;
  height: 26px;
  border: rgb(141, 141, 139) 2px solid;
  outline: 1px solid gray;
  padding: 4px;
  border-radius: 50% !important;
  background-color: gray;
  fill: $media-background-color;
  box-shadow: none;

  &:hover {
    background-color: rgba($media-background-color, 0.7);
    border: white 4px solid;
    outline: none;
    padding: 2px;
    fill: $main-color;
    box-shadow: none;
  }
}

.icon-big {
  height: 2rem;
  width: 2rem;
  border: white 2px solid;
}

.icon-big:hover {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.function-field {
  position: relative;

  .function-icon {
    position: absolute;
    top: 2px;
    right: 10.5px;
    z-index: 2;
  }
}

.edit-field {
  position: relative;
  height: 100%;
  /* wprowadzone ze względu na wyrównanie wierszy kolekcji */
  border-radius: 0.6rem;
}

.main-content-container .edit-field {
  height: auto; // dla wyrównywanych kolumn nie można ustawiać wysokości
}

.edit-field {
  background-color: rgba(0, 0, 0, 0.1);
}

.edit-field .icon {
  position: absolute;
  top: 10px;
  right: 10.5px;
  z-index: 2;
}

.edit-field.narrow .icon {
  position: absolute;
  top: 0.1rem;
}


.edit-field .icon div {
  float: left;
  margin-left: 8px;
}

.short-description {
  padding: 0.5rem;
  font-size: 1rem;
  font-style: italic;
  white-space: pre-wrap;
  min-height: 4rem;
  background-color: rgba($main-opposed-color, 0.2);
  border-radius: 3.5px;
}

// .form-control:read-only {
//   background-color: rgba($main-opposed-color, 0.2);
//   border-color: gray !important;
// }

.form-floating .form-control:read-only+.form-label {
  position: absolute;
  font-weight: 500;
  font-size: 1.1rem;
  opacity: 1;
  left: 10px;
}

.edit-field .form-floating .form-control:read-only+.form-label {
  left: 0px;
}

.editable-collection {
  //margin: 1rem 0.5rem;
  padding-top: 2.5rem;
}

.btn-link-click {
  background-color: transparent;
  color: black !important;
  border: 0px;
}

.btn-link-click:hover,
.btn-link-click:focus {
  background-color: transparent !important;
  color: $mainbar-background-color !important;
}

.test-select-container {
  padding-top: 0.2rem;
}

.tags-select-container {
  padding-top: 0.2rem;
}

.test-select {
  &__multi-value {
    width: 95%;
  }
}


.driving-select,
.test-select {
  &__multi-value {
    justify-content: space-between;
    background-color: #e7d851 !important;
    border-radius: 0.2rem !important;

    &__label,
    &__remove,
    &__remove {
      color: black !important;

      svg {
        fill: blue;
      }
    }

    &__remove:hover {
      background-color: #e7d851 !important;

      svg {
        fill: red;
      }
    }
  }
}

.tags-select {
  &__control {
    background-color: rgba($form-control-background-color, 0.8) !important;
  }

  &__indicators {
    svg {
      fill: $mainbar-background-color;
    }
  }

  &__multi-value {
    background-color: $mainbar-background-color !important;
    border: 1px solid $mainbar-background-color !important;
    border-radius: 0.6rem !important;

    svg {
      .st0 {
        fill: transparent !important;
      }

      .st1 {
        fill: white !important;
      }

      fill: $mainbar-background-color;
    }

    &__label,
    &__remove,
    &__remove {
      color: $main-opposed-color !important;

      svg {
        fill: $main-opposed-color;
      }
    }

    &__remove:hover {
      border-radius: 0.6rem;
      background-color: $font-color !important;

      svg {
        fill: $mainbar-background-color;
      }
    }
  }

  &__option {
    margin: 0;
  }

  &__menu {
    background-color: $mainbar-background-color !important;
    color: $font-color !important;
    z-index: 10 !important;

    svg {
      height: 10rem !important;
    }
  }

  &__option--is-focused,
  &__option--is-focused.tags-select__option--is-selected {
    color: $mainbar-background-color !important;
    background-color: $font-color !important;
  }

  &__option--is-selected {
    color: $mainbar-background-color !important;
    background-color: rgba($main-color, 0.7) !important;
  }

  &__option {
    input[type="checkbox"]:checked+label::after {
      content: '✔';
      color: $font-opposed-color;
      position: absolute;
      background: transparent;
      top: 0;
      left: 0.2rem;
    }

    input[type="checkbox"] {
      position: absolute;
      left: -999rem;
    }

    input[type="checkbox"]+label {
      position: relative;
      overflow: hidden;
      cursor: pointer;
    }



    input[type="checkbox"]+label::before {
      content: '';
      display: inline-block;
      vertical-align: -25%;
      height: 1.1rem;
      width: 1.1rem;
      background-color: $form-control-background-color;
      border: 1px solid $font-opposed-color;
      border-radius: 3.5px;
      box-shadow: none;
      margin-right: 0.5rem;
      margin-bottom: 0.2rem;
    }
  }
}


input[type="date"] {
  max-width: 150px;
}

.body-image {
  position: relative;
  padding: 0;
  margin: 0;

  .body-area {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    svg {
      .st0 {
        fill: transparent;
      }

      .st1 {
        fill: red;
      }

      fill: $media-background-color;
    }
  }
}

.my-form-view .hr {
  border-top: 1px solid yellow;
}

.my-form-view .btn-author {
  padding-left: 0.5rem;

  svg {
    height: 1.6rem !important;
    width: 1.6rem !important;
  }

  span {
    color: yellow;
    vertical-align: middle;
    font-weight: 300;
    font-size: 1.1rem;
  }
}

.select-control {
  min-height: 6rem;
  background-color: rgba($main-opposed-color, 0.2);
  padding: 0.5rem 0.75rem;
  border-radius: 3.5px;

  .form-label {
    font-size: 1rem;
    font-weight: 500;
  }
}

.reading-field {
  padding-top: 16px;
  margin-top: 18px;
  border-radius: 3.5px;
  background-color: $main-color;
}

.modal-content {
  .modal-header {
    border-color: $font-color;
  }

  border-radius: 0.6rem;
  background-color: white;
  color: $font-color;
}

.modal-transparent {
  .modal-content {
    border: none;
    background-color: rgba(30, 213, 54, 0);
    color: #000000;
  }
}

svg {
  fill: $font-color;
}

.icon-red {
  fill: darkred;
}

.icon-green {
  fill: green;
}

.centred-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

button:hover svg {
  fill: $font-opposed-color;
}

.black-icon {
  fill: black;
}

.text-opposed-color {
  color: $font-opposed-color;
}

.form-search {
  width: 50%;
  margin: 50px auto;
}

.footer-logo {
  max-height: 2rem;
}

.page-container {
  position: relative;
  min-height: 100vh;
}

.page-content {
  margin-top: 1.2rem;
  padding-bottom: 80px;

  @include media-breakpoint-down(lg) {
    padding-bottom: 160px;
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 200px;
  }
}

footer {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  padding-top: 1rem;
  padding-bottom: 0.5rem;

  font-size: 1rem;
  color: $menu-font-color;

  .contact {
    font-size: 0.9rem;
  }

  a {
    color: $font-color;

    &:hover {
      color: white;
    }
  }
}

.modal-on-modal {
  a {
    &:hover {
      color: white;
    }
  }
}

.search-tab .nav-tabs .nav-link:hover .badge.bg-primary,
button.active .badge.bg-primary {
  background-color: $font-opposed-color !important;
  color: $main-opposed-color;
}

.search-tab .nav-tabs,
.search-tab .nav-tabs .nav-link {
  margin-left: 4px;
  background-color: transparent;
  border-color: $main-opposed-color !important;
  color: $main-opposed-color !important;
}

.search-tab .nav-tabs .nav-link {
  margin-left: 4px;
  background-color: rgba($card-background-color, 0.9);
  border-color: $main-opposed-color !important;
  color: $main-opposed-color !important;
}

.search-tab .nav-tabs .nav-link:hover,
.search-tab .nav-tabs .nav-link.active {
  background-color: $main-opposed-color !important;
  border-color: $main-opposed-color !important;
  color: $font-opposed-color !important;
}

.react-multiple-carousel__arrow {
  background-color: rgba(255, 255, 0, 0.6) !important;
  min-width: 8rem !important;
  min-height: 8rem !important;

  &::before {
    color: $mainbar-background-color !important;
    font-size: 6rem !important;
  }
}

.carousel-button-group {
  position: absolute;
}

.more-btn {
  padding: 2rem;
  border-radius: 3rem;
}

.profile-input {
  height: 2.2rem;
  margin-bottom: 1rem;

  svg {
    fill: $font-opposed-color;
    height: 2rem !important;
    width: 2rem !important;
    margin: 1rem;

    &:hover {
      fill: $main-color;
    }
  }

  a {
    text-decoration: none;
    color: $font-opposed-color;

    &:hover {
      color: $main-color;
    }
  }
}

.search-link-menu {
  padding-left: 0.6rem !important;
  padding-right: 0.6rem !important;
  border: 2px solid $main-opposed-color;
  color: $main-opposed-color !important;
  border-radius: 6px;

  &:hover {
    background-color: $main-opposed-color !important;
    color: $font-opposed-color !important;

    svg {
      fill: $font-opposed-color !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .navbar-nav {
    margin-top: 0.6rem;
  }
}

@include media-breakpoint-down(lg) {
  .search-link-menu {
    margin-bottom: 0.6rem;
  }
}

.modal.show .image-modal .modal-content {
  color: $font-opposed-color;
  border-radius: 0.6rem;

  .modal-body {
    padding: 0.4rem;
  }

  background-color: rgba($main-opposed-color, 0.8);
  border: 1px solid rgba($card-background-color, 0.25);
  box-shadow: none;
  backdrop-filter: blur(15px);

  img {
    width: 100%;
    height: auto;
  }
}


.my-small-card .image-magnifier {
  position: relative;

  img {
    width: 100%;
  }
}

.my-any-media-img .image-magnifier {
  position: relative;
}

.image-magnifier {
  img {
    margin-right: auto !important;
    margin-left: auto !important;
    display: block !important;
  }

  svg {
    background-color: rgba($card-background-color, 0.3);
    border-radius: 30%;
    padding: 0.5rem;
    position: absolute;
    top: calc(50% - 1.5rem);
    left: calc(50% - 1.5rem);
    width: 3rem;
    height: 3rem;
  }

  svg:hover,
  svg:focus-visible {
    background-color: rgba($card-background-color, 0.6);
    top: calc(50% - 2rem);
    left: calc(50% - 2rem);
    width: 4rem;
    height: 4rem;
  }
}

.my-small-card:focus-visible .image-magnifier svg {
  background-color: rgba($card-background-color, 0.6);
  top: calc(50% - 2rem);
  left: calc(50% - 2rem);
  width: 4rem;
  height: 4rem;
}

.grecaptcha-badge {
  top: 80px !important;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}

.loading-spinner-height {
  min-height: 3rem;
}

tr.grid-highlight-row td {
  background-color: #f4f473;
}

.sm-form-label {
  font-size: 0.9rem;

}

.toc * dd {
  margin-left: 1rem;
}

.section * .figure,
.section>.figure,
.chapter * .figure,
.chapter>.figure {
  display: block;
}

.last-editor {
  position: absolute;
  color: red;
  left: 2vh;
}

.last-editor-4vh {
  position: absolute;
  color: red;
  left: 4vh;
}

.last-editor-inline {
  color: red;
  margin-left: 1vh;
}

.markdown {
  padding-left: 20px;
  display: block;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.markdown h1 {
  color: purple;
}

.markdown ul,
.markdown ol {
  margin-left: 1em;
}

.markdown li {
  display: list-item;
  margin-bottom: 0.5em;
}

.markdown h1 {
  display: block;
  margin-top: 1em;
  margin-bottom: 0.5em;
  padding-bottom: 0.2em;
  border-bottom: 1px solid;
}

.markdown hr {
  display: block;
  border: none;
  border-top: 3px solid #333;
  margin: 1.5em 0;
}

.limited-markdown {
  max-width: 1000px;
  padding-left: 20px;
}

.full-height-calendar {
  height: calc(70vh - 50px) !important;
}

td video {
  max-width: 100%;
  /* Dopasowanie do szerokości komórki */
  max-height: 100%;
  /* Dopasowanie do wysokości komórki */
  object-fit: contain;
  /* Zapobiega zniekształceniom */
}

.z-index-0 {
  z-index: 0;
}


.grid-page-size {
  width: 5rem;
}

.page-item {
  z-index: 0;
}

.grid-filter-size {
  width: 20rem;
}

.grid-clickable-row {
  cursor: pointer;
}

.grid-not-clickable-row {
  cursor: default;
}

.grid-filter-column {
  width: 100%;
  box-sizing: border-box;
}

tr.grid-highlight-row td {
  background-color: #f4f473;
}

.grid-summary-foot {
  font-weight: bold;
  border-top: 2px solid;
}

.dark-icon {
  fill: currentColor !important;
  color: inherit !important;
}

.image-style {
  width: 100px;
  height: auto;
}

/* Upewnia się, że nagłówki resources są wyrównane z kolumnami */
.rbc-time-header-content {
  display: flex !important;
  width: 100% !important;
}

/* Poprawia wyrównanie nagłówków do kolumn */
.rbc-time-header-content .rbc-row-resource {
  display: flex !important;
  flex-grow: 1 !important;
  justify-content: space-between !important;
  width: 100% !important;
}

/* Zapewnia elastyczne dopasowanie nagłówków */
.rbc-header {
  flex-grow: 1 !important;
  flex-basis: 0 !important;
  min-width: 0 !important;
  text-align: center !important;
  white-space: normal !important;
  word-wrap: break-word !important;

}

/* Upewnia się, że nagłówki w widoku Tygodnia nie są popsute */
.rbc-time-header.rbc-overflowing {
  margin-right: 0 !important;
}

.selector-healthcare {
  z-index: 100 !important;
}

.stat-card {
  border-radius: 20px;
  background-color: #f8f9fa;
  text-align: center;
  min-height: 120px;
  flex-direction: column;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.stat-card:hover {
  transform: translateY(-5px);
}

.stat-title {
  padding: 8px;
  font-size: 1rem;
  color: #333;
  margin-bottom: 8px;
}

.stat-value-blue {
  font-size: 2.5rem;
  font-weight: bold;
  color: #007bff;
}

.stat-value-green {
  font-size: 2.5rem;
  font-weight: bold;
  color: #00b35c;
}

.stat-value-dark-blue {
  font-size: 2.5rem;
  font-weight: bold;
  color: #0056b3;
}

.stat-value-dark-green {
  font-size: 2.5rem;
  font-weight: bold;
  color: #008746;
}

/* Ogólny kontener wykresu */
.chart-container {
  max-height: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  border-radius: 10px;
  padding: 20px;
  background-color: #ffffff;
  width: 100%;
  /* Kontener ma pełną szerokość */
  display: flex;
  justify-content: center;
  /* Wyśrodkowanie wykresu */
}

.chart-wrapper {
  width: 100%;
  /* Ustawienie na 100% dostępnej szerokości */
  max-width: 800px;
  /* Maksymalna szerokość kontenera */
}

.hearing-chart-container {
  width: 600px;
  height: 500px;
  padding: 20px;
}